import type from "./type";
import {
  doAuthPost,
  doGet,
  doPost,
  doPostAuth,
  doGetParams,
  doPostDataAndGet,
  doPostFormData,
  doPostWithState,
  doGetData,
  doGetSingleUser,
  doLogOut,
  doPostFormDataWithoutToast,
} from "../../config/DataService";
import { Api } from "../../config/Api";
import Index from "../../container/Index";

// Add User action
export const payForEvoluteIdea = async (formData) => {
  const res = await doPostFormDataWithoutToast(
    Api.Wallet.evaluteIdea,
    formData
  );
  return res?.data;
};

export const putOnSale = async (formData) => {
  const res = await doPostFormDataWithoutToast(Api.Wallet.putOnSale, formData);
  return res?.data;
};
export const sellIdea = async (formData) => {
  const res = await doPostFormDataWithoutToast(Api.Wallet.sellIdea, formData);
  return res?.data;
};
export const buyPriceAddress = async (formData) => {
  const res = await doPostFormDataWithoutToast(
    Api.Wallet.buyPriceAdress,
    formData
  );
  return res?.data;
};

export const buySellTransactionHash = async (formData) => {
  const res = await doPostFormDataWithoutToast(
    Api.Wallet.transactionHash,
    formData
  );
  return res?.data;
};

export const buyId = async (formData) => {
  const res = await doPostFormDataWithoutToast(Api.Wallet.buyId, formData);
  return res?.data;
};

export const conversionRate = async () => {
  const res = await doGet(Api.Admin.getConvertionRate);
  return res?.data;
};

export const payForPFP = async (formData) => {
  const res = await doPostFormDataWithoutToast(Api.Wallet.pfp, formData);
  return res?.data;
};

export const becameWarden = async (formData) => {
  const res = await doPostFormDataWithoutToast(
    Api.Wallet.becameworden,
    formData
  );
  return res?.data;
};

export const buyIdea = async (formData) => {
  const res = await doPostFormDataWithoutToast(Api.Wallet.buyIdea, formData);
  return res?.data;
};

export const masterProcessingIdea = async (formData) => {
  const res = await doPostFormDataWithoutToast(
    Api.Wallet.masterProcessingIdea,
    formData
  );
  return res?.data;
};
//  Start Actions //

// Add User action
export const addUserAction = async (formData) => {
  const res = await doPostFormData(Api.AddUser.addUser, formData);
  return res?.data;
};

// edit User action
export const editUserAction = async (formData, adminToken) => {
  const res = await doPostFormData(Api.Admin.editUser, formData, adminToken);
  return res?.data;
};
// delete User action
export const deleteUserAction = async (id, adminToken) => {
  const res = await doPostFormData(Api.Admin.deleteUser, id, adminToken);
  return res?.data;
};

// Add User otp send action
export const OtpSendAction = async (formData) => {
  const res = await doPostFormData(Api.AddUser.otpSend, formData);
  return res?.data;
};
// Add User otp resend action
export const resendOtpAction = async (formData) => {
  const res = await doPostFormData(Api.AddUser.otpSend, formData);
  return res?.data;
};

// Add User otp verify action
export const userotpVerifyAction = async (formData) => {
  const res = await doPostFormData(Api.AddUser.verifyOtp, formData);
  return res?.data;
};
// Add User otp verify action
export const categorySubmitAction = async (formData) => {
  const res = await doPostFormData(Api.Admin.categorySubmit, formData);
  return res?.data;
};

// log out action
export const adminLogOutAction = (navigate, data) => (dispatch) => {
  if (data != "not_authorized") {
    Index.toast.success("You have successfully logged out!");
  }
  dispatch({
    type: type.ADMIN_LOGOUT,
  });
  navigate("/login");
};

// log in action
export const adminLogin = (value, navigate, token) => async (dispatch) => {
  let data = {
    email: value?.email,
    password: value?.password,
    token: token,
  };
  await doPost(Api.Admin.login, data, navigate, "dashboard")
    .then((res) => {
      dispatch({
        type: type.ADMIN_LOGIN,
        payload: res?.data?.data,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

//forgot password  action
export const forgotPasswordAction = async (value, navigate) => {
  const res = await doPostWithState(
    Api.Admin.forgetPassword,
    value,
    navigate,
    "/admin/otp-verify"
  );
  return res;
};
//OTP verification  action
export const otpVerifyAction = async (value, navigate) => {
  const res = await doPostWithState(
    Api.Admin.verifyOtp,
    value,
    navigate,
    "/admin/reset-password"
  );
  return res;
};

//reset password  action
export const resetPasswordAction = async (
  value,
  token,
  navigate,
  setLoading
) => {
  const res = await doPostAuth(
    Api.Admin.resetPassword,
    value,
    token,
    navigate,
    "/"
  )
    .then((res) => {
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    })
    .catch((error) => {
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    });
  // return res;
};

//update profile  action

export const updateProfileAction = (value, token) => async (dispatch) => {
  await doPostFormData(Api.Admin.updateProfile, value, token)
    .then((res) => {
      // Index.toast.success(res?.data?.message);
      dispatch({
        type: type.ADMIN_GET_UPDATE_DATA,
        payload: [res?.data?.data],
      });
    })
    .catch((error) => {
      // Index.toast.error(error?.data?.message);
      console.log(error);
    });
};
//change password  action
export const changepasswordAction = async (value, token) => {
  const res = await doPostFormData(Api.Admin.changePassword, value, token);
  return res;
};

// get admin profile   action

export const getAdminProfile = (token) => async (dispatch) => {
  await doGet(Api.Admin.getAdminProfile, token)
    .then((res) => {
      dispatch({
        type: type.ADMIN_GET_UPDATE_DATA,
        payload: res?.data?.data,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

// get  list
export const getUserWardenEmployeeList = async (role, token) => {
  const res = await doPostDataAndGet(Api.Admin.getUserList, role, token);
  return res?.data?.data;
};
// get  list
export const getUserListAction = async (token) => {
  const res = await doGet(Api.Admin.getUserList, token);
  return res?.data?.data;
};

// addEdit privacy policy
export const addEditPrivacy = async (data, token) => {
  const res = await doPostFormData(Api.Admin.addEditPrivacy, data, token);
  return res?.data?.data;
};
// addEdit term condition
export const addEdittermsCondition = async (data, token) => {
  const res = await doPostFormData(
    Api.Admin.addEdittermsCondition,
    data,
    token
  );
  return res?.data?.data;
};

// warden get cms
export const getWardenCms = async () => {
  const res = await doGet(Api.Admin.getWardenCms);
  return res?.data?.data;
};

// get warden steps
export const getWardenSteps = async (token) => {
  const res = await doGet(Api.Admin.getWardenSteps, token);
  return res?.data?.data;
};

//nft  addEdit privacy policy
export const addEditWardenSteps = async (data, token) => {
  const res = await doPostFormData(Api.Admin.addEditWardenSteps, data, token);
  return res?.data?.data;
};

//nft  addEdit privacy policy
export const addEditWardenRoles = async (data, token) => {
  const res = await doPostFormData(Api.Admin.addEditWardenRoles, data, token);
  return res?.data?.data;
};

// nft get cms
export const getNftCms = async (token) => {
  const res = await doGet(Api.Admin.getNftCms, token);
  return res?.data?.data;
};

//nft  addEdit privacy policy
export const nftAddEditPrivacy = async (data, token) => {
  const res = await doPostFormData(Api.Admin.nftAddEditPrivacy, data, token);
  return res?.data?.data;
};
// nft addEdit term condition
export const nftAddEditTermsCondition = async (data, token) => {
  const res = await doPostFormData(
    Api.Admin.nftAddEdittermsCondition,
    data,
    token
  );
  return res?.data?.data;
};

//  addEdit about us
export const addEditAboutUs = async (data, token) => {
  const res = await doPostFormData(Api.Admin.addEditAboutUs, data, token);
  return res?.data?.data;
};
//  addEdit contact us
export const addEditContactUS = async (data, token) => {
  const res = await doPostFormData(Api.Admin.addEditContactUS, data, token);
  return res?.data?.data;
};
// get cms
export const getCms = async (token) => {
  const res = await doGet(Api.Admin.getCms, token);
  return res?.data?.data;
};
//

// active-deactive user
export const activeDeactiveUser = async (id, token) => {
  // debugger;
  const res = await doPostDataAndGet(Api.Admin.activeDeactiveUser, id, token);
  return res?.data;
};

export const getUserDetailListAction = async (id, token, navigate) => {
  const res = await doGetSingleUser(Api.Admin.userDetail, id, token, navigate);
  return res?.data;
};

// get single leafNode --------
export const getLeafNodeDetailAction = async (id, token) => {
  const res = await doGetSingleUser(
    Api.Admin.getSingleLeafNodeDetail,
    id,
    token
  );

  return res?.data;
};
// get  Category Management
export const getCategoryManagementAction = async (token) => {
  const res = await doGetData(Api.Admin.getallcategory, token);
  return res?.data?.data;
};
// get tag management
export const getTagManagementAction = async (token) => {
  const res = await doGetData(Api.Admin.getalltag, token);
  return res?.data?.data;
};
//get-sub-category-By-Id
export const getSubCategoryManagementAction = async (id, token) => {
  const res = await doGet(`${Api.Admin.getSubCategory}?parentId=${id}`, token);
  return res?.data?.data;
};
// edit  Category Management
export const editCategoryManagementAction = async (formData, token) => {
  const res = await doPostFormData(Api.Admin.editCategory, formData, token);
  return res?.data;
};
// edit tag management
export const editTagManagementAction = async (formData, token) => {
  const res = await doPostFormData(Api.Admin.editTag, formData, token);
  return res?.data;
};
// add  Category Management
export const addCategoryManagementAction = async (formData, token) => {
  const res = await doPostFormData(Api.Admin.addCategory, formData, token);
  return res?.data;
};
// add Tags management
export const addTagsManagementAction = async (formData, token) => {
  const res = await doPostFormData(Api.Admin.addTag, formData, token);
  return res?.data;
};
// delete  Category Management
export const deleteCategoryManagementAction = async (id, token) => {
  const res = await doPostFormData(Api.Admin.deleteCategory, id, token);
  return res?.data;
};
// delete tag management
export const deleteTagManagementAction = async (id, token) => {
  const res = await doPostFormData(Api.Admin.deleteTag, id, token);
  return res?.data;
};
// delete  Category Management
export const nftListAction = async (token) => {
  const res = await doGet(Api.Admin.nftList, token);
  return res?.data;
};

//get Dashboard--
export const getDashboardDataAction = async (token) => {
  const res = await doGet(Api.Admin.getDashboardData, token);
  return res?.data;
};

//get Master Warden Dashboard--
export const getMasterWardenDashboardDataAction = async (token) => {
  const res = await doGet(Api.Admin.getMasterWardenDashboardData, token);
  return res?.data;
};

//add subCategory
export const addSubCategoryManagementAction = async (formData, token) => {
  // debugger;

  const res = await doPostFormData(Api.Admin.addSubCategory, formData, token);
  return res?.data;
};
//delete subcategory
export const deleteSubCategoryManagementAction = async (id, token) => {
  // debugger;
  const res = await doPostFormData(Api.Admin.deleteSubCategory, id, token);
  return res?.data;
};

//  get activity log
export const getActivityLogAction = async (id, adminToken) => {
  const res = await doPostDataAndGet(Api.Admin.getActivityLog, id, adminToken);
  return res?.data?.data;
};

//get-mainIdea-to-leafIdea-list-----
export const getIdeaToleafIdeaAction = async (id, adminToken) => {
  const res = await doPostDataAndGet(
    Api.Admin.getIdeaToleafIdeaList,
    id,
    adminToken
  );
  return res?.data?.data;
};

// get warden actions list
export const getTreeNFTList = async (id, token) => {
  const res = await doGetParams(Api.AddUser.treeNFTRequest, id, token);
  return res?.data;
};

// active-deactive nft
export const activeDeactiveNftAction = async (id, token) => {
  // debugger;
  const res = await doPostDataAndGet(Api.Admin.nftActiveDeactive, id, token);
  return res?.data;
};
// Category Management
export const userContactListAction = async (token) => {
  const res = await doGet(Api.Admin.userContactList, token);
  return res?.data;
};
// get PFP list
export const getPfpListAction = async (token) => {
  const res = await doGet(Api.Admin.PFPList, token);
  return res?.data;
};

// edit PFP
export const editPfpAction = async (formData, token) => {
  const res = await doPostFormData(Api.Admin.editPfp, formData, token);
  return res?.data;
};

// add PFP
export const addPfpAction = async (formData, token) => {
  const res = await doPostFormData(Api.Admin.addPfp, formData, token);
  return res?.data;
};
// delete  PFP
export const deletePfpAction = async (id, token) => {
  const res = await doPostFormData(Api.Admin.deletePfp, id, token);
  return res?.data;
};

// contact request status update
export const contactStatusUpdateAction = async (formData, token) => {
  const res = await doPostFormData(
    Api.Admin.contactStatusUpdate,
    formData,
    token
  );
  return res?.data;
};

// nft get cms
export const getInviteWardenList = async (token) => {
  const res = await doGet(Api.Admin.inviteWardenList, token);
  return res?.data?.data;
};

// contact request status update
export const addWarden = async (formData, token) => {
  const res = await doPostFormData(Api.Admin.addWarden, formData, token);
  return res?.data;
};

// get white listed user
export const getWhiteListedUser = async (token) => {
  const res = await doGet(Api.Admin.whiteListedUser, token);
  return res?.data?.data;
};

// get experts
export const getExpertList = async (token) => {
  const res = await doGet(Api.Admin.expertList, token);
  return res?.data?.data;
};
// white listed approve reject
export const whiteListedApprove = async (formData, token) => {
  const res = await doPostFormData(
    Api.Admin.whiteListedApproveReject,
    formData,
    token
  );
  return res?.data;
};
// experts approve reject
export const expertsApprove = async (formData, token) => {
  const res = await doPostFormData(
    Api.Admin.expertApproveReject,
    formData,
    token
  );
  return res?.data;
};
// get social posts
export const socialPostListAction = async (token) => {
  const res = await doGet(Api.Admin.socialPostList, token);
  return res?.data?.data;
};
// delete social post action
export const deleteSocialPostAction = async (formData, adminToken) => {
  const res = await doPostFormData(
    Api.Admin.socialPostDelete,
    formData,
    adminToken
  );
  return res?.data;
};

// active-deactive Reported post
export const activeDeactiveReportedPostAction = async (id, token, navigate) => {
  // debugger;
  const res = await doPostDataAndGet(
    Api.Admin.reportedPostActiveDeactive,
    id,
    token,
    navigate
  );
  return res?.data;
};

// get community group list
export const communityGroupList = async (token) => {
  const res = await doGet(Api.Admin.communityGroupList, token);
  return res?.data?.data;
};
// active-deactive nft
export const communityGroupMemberList = async (id, token) => {
  // debugger;
  const res = await doPostDataAndGet(
    Api.Admin.communityGroupMemberList,
    id,
    token
  );
  return res?.data;
};

// Active deactive group member
export const activeDeactiveGroupMember = async (formData, token) => {
  const res = await doPostFormData(
    Api.Admin.activeDeactiveGroupMember,
    formData,
    token
  );
  return res?.data;
};
// Active deactive group
export const activeDeactiveGroup = async (formData, token) => {
  const res = await doPostFormData(
    Api.Admin.activeDeactiveGroup,
    formData,
    token
  );
  return res?.data;
};

// get report list
export const reportListAction = async (token) => {
  const res = await doGet(Api.Admin.reportList, token);
  return res?.data?.data;
};
// Edit reason action
export const editReasonAction = async (formData, token) => {
  const res = await doPostFormData(Api.Admin.editReport, formData, token);
  return res?.data;
};

// Add reason action
export const addReasonAction = async (formData, token) => {
  const res = await doPostFormData(Api.Admin.addReport, formData, token);
  return res?.data;
};

// Delete reason action
export const deleteReasonAction = async (formData, token) => {
  const res = await doPostFormData(Api.Admin.deleteReport, formData, token);
  return res?.data;
};
//Get discussion
export const getDiscussionAction = async (id, token) => {
  const res = await doPostDataAndGet(Api.Admin.getDiscussion, id, token);
  return res?.data;
};
// Get reports
export const getReportAction = async (data, token) => {
  const res = await doPostDataAndGet(Api.Admin.getReports, data, token);
  return res?.data;
};

// LogOut Action
export const logOutAction = async (token) => {
  const res = await doLogOut(Api.Admin.logOut, token);
  return res;
};

// get report list
export const getMasterWardenAction = async (token) => {
  const res = await doGet(Api.Admin.masterWardenList, token);
  return res?.data?.data;
};

// Add Warden action
export const addMasterWardenAction = async (formData, adminToken) => {
  const res = await doPostFormData(
    Api.Admin.addMasterWarden,
    formData,
    adminToken
  );
  return res?.data;
};

// edit warden action
export const editMasterWardenAction = async (formData, adminToken) => {
  const res = await doPostFormData(
    Api.Admin.editMasterWarden,
    formData,
    adminToken
  );
  return res?.data;
};

// delete User action
export const deleteMasterWardenAction = async (id, adminToken) => {
  const res = await doPostFormData(
    Api.Admin.masterWardenDelete,
    id,
    adminToken
  );
  return res?.data;
};

// Warden  nft list
export const getMasterWardenNftList = async (token) => {
  const res = await doGet(Api.MasterWarden.getInappropriateNftList, token);
  return res?.data?.data;
};

// Warden update nft status
export const updateNftStatus = async (formData, token) => {
  const res = await doPostFormData(
    Api.MasterWarden.updateNftAction,
    formData,
    token
  );
  return res?.data;
};

// Warden  nft action list
export const wardenNftActionList = async (data, token, navigate) => {
  const res = await doPostDataAndGet(
    Api.MasterWarden.nftActionList,
    data,
    token,
    navigate
  );
  return res?.data?.data;
};

// Master warden get warden  list
export const getWardenList = async (token) => {
  const res = await doGet(Api.MasterWarden.wardenList, token);
  return res?.data?.data;
};

// warden warning action
export const sendWardenWarningAction = async (data, adminToken) => {
  const res = await doPostFormData(
    Api.MasterWarden.wardenWarnig,
    data,
    adminToken
  );
  return res?.data;
};

//  warden warning  list
export const getWardenWarningAction = async (token) => {
  const res = await doGet(Api.MasterWarden.wardenWarnigList, token);
  return res?.data?.data;
};

// get transaction history list
export const transactionHistory = async (token) => {
  const res = await doGet(Api.Admin.getTransactionHistory, token);
  return res?.data?.data;
};

// get pending transactions
export const pendingTransaction = async (token) => {
  const res = await doGet(Api.Admin.getPendingTransaction, token);
  return res?.data?.data;
};

// get master warden transaction history list
export const masterWardenTransactionHistory = async (token) => {
  const res = await doGet(
    Api.MasterWarden.getMasterWardenTransactionHistory,
    token
  );
  return res?.data?.data;
};

// Warden to user status update
export const updateWardenToUserStatus = async (formData, token) => {
  const res = await doPostFormData(
    Api.MasterWarden.changeWardenToUser,
    formData,
    token
  );
  return res?.data;
};

// get reported posts
export const reportedPostListAction = async (token) => {
  const res = await doGet(Api.Admin.reportedPostList, token);
  return res?.data?.data;
};

// get warden actions list
export const getWardenActionsList = async (id, token, navigate) => {
  const res = await doGetSingleUser(
    Api.Admin.getSingleWardenActions,
    id,
    token,
    navigate
  );
  return res?.data;
};

// get PDF idea list
export const getPFPIdeaList = async (token) => {
  const res = await doGet(Api.Admin.getPFPIdeas, token);
  return res?.data?.data;
};

// PFP Ideas status update
export const updatePfpIdeaStatus = async (formData, token) => {
  const res = await doPostFormData(
    Api.Admin.updatePFPIdeaAction,
    formData,
    token
  );
  return res?.data;
};

// Add Admin otp resend action
export const adminResendOtpAction = async (formData) => {
  const res = await doPostFormData(Api.Admin.otpSend, formData);
  return res?.data;
};

//Admin Notification List
export const adminNotificationListAction =
  (token, navigate) => async (dispatch) => {
    await doGet(Api.Admin.notificationList, token, navigate)
      .then((res) => {
        dispatch({
          type: type.ADMIN_GET_PUSH_NOTIFICATIONS,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

// Expert User NFT List
export const getMasterWardenExperNftList = async (token) => {
  const res = await doGet(Api.MasterWarden.expertUserIdeaList, token);
  return res?.data?.data;
};

// Expert NFT Idea Status Update
export const updateExpertNftStatus = async (formData, token) => {
  const res = await doPostFormData(
    Api.MasterWarden.expertIdeaActionUpdate,
    formData,
    token
  );
  return res?.data;
};

// get reported posts NFT Request List
export const inappropriateNFTRequestListAction = async (token) => {
  const res = await doGet(
    `${Api.Admin.inappropriateNFTRequestList}?type=Nft_idea`,
    token
  );
  return res?.data?.data;
};

// get reported posts PFP Request List
export const inappropriatePFPRequestListAction = async (token) => {
  const res = await doGet(
    `${Api.Admin.inappropriatePFPRequestList}?type=Pfp_idea`,
    token
  );
  return res?.data?.data;
};

// get reported posts Expert Request List
export const inappropriateExpertRequestListAction = async (token) => {
  const res = await doGet(
    `${Api.Admin.inappropriateExpertRequestList}?type=Expert_idea`,
    token
  );
  return res?.data?.data;
};

// inappropriate update nft status
export const inappropriateUpdateStatus = async (formData, token) => {
  const res = await doPostFormData(
    Api.MasterWarden.inappropriateStatusUpdateAction,
    formData,
    token
  );
  return res?.data;
};

// Admin approve reject inappropriate request status
export const inappropriateApproveRejectUpdateStatus = async (
  formData,
  token
) => {
  const res = await doPostFormData(
    Api.Admin.inappropriateStatusUpdateRequest,
    formData,
    token
  );
  return res?.data;
};

// Notification contact detail api
export const getContactDetailsAction = async (id, token, navigate) => {
  const res = await doGet(
    `${Api.Admin.getContactDetailRequest}/${id}`,
    token,
    navigate
  );
  return res?.data?.data;
};

// get Social Post Details action
export const getSocialPostDetailAction = async (data, token, navigate) => {
  const res = await doPostDataAndGet(
    Api.Admin.getSocialDetailRequest,
    data,
    token,
    navigate
  );
  return res?.data?.data;
};

// Post read unread Push Notification action
export const readUnreadNotificationAction = async (data, token, navigate) => {
  const res = await doPostDataAndGet(
    Api.AddUser.readUnreadNotificationRequest,
    data,
    token,
    navigate
  );
  return res?.data?.data;
};

// get Wallet Address
export const getWalletAddress = async (token, navigate) => {
  const res = await doGet(Api.Admin.getWalletAddress, token, navigate);
  return res?.data?.data;
};

// addEdit Wallet Address
export const addEditWalletAddress = async (data, token) => {
  const res = await doPostFormData(Api.Admin.addEditWalletAddress, data, token);
  return res?.data?.data;
};

// get Reward token
export const getRewardToken = async (token, navigate) => {
  const res = await doGet(Api.Admin.getRewardTokenRequest, token, navigate);
  return res?.data?.data;
};

// addEdit Reward token
export const addEditRewardToken = async (data, token) => {
  const res = await doPostFormData(
    Api.Admin.addEditRewardTokenRequest,
    data,
    token
  );
  return res?.data?.data;
};

// get all referrals
export const getAllReferralUsersAction = async (token) => {
  const res = await doGet(Api.Admin.getReferralsUserRequest, token);
  return res?.data;
};

// get reward log histroy details
export const getAllReferralLogHistoryAction = async (token) => {
  const res = await doGet(Api.Admin.getReferralsLogHistoryRequest, token);
  return res?.data;
};
