import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers5/react";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import tokenAbi from "../../Connectivity/abi/tokenAbi.json";
import { becameWarden } from "../../../redux/admin/action";
import WalletLoader from "../../../components/common/WalletLoader";
import CircularProgress from "@mui/material/CircularProgress";
import PagesIndex from "../../PagesIndex";
const projectId = "2fa6c12dde1b5cd1a88e4fd4cb690bca";

const PolygonMainnet = {
  chainId: 137,
  name: "Polygon Mainnet",
  currency: "MATIC",
  explorerUrl: "https://polygonscan.com/",
  rpcUrl:
    "https://polygon-mainnet.infura.io/v3/8c79ca71179c4e318999034a96a93f31",
};

const metadata = {
  name: "Episapient.io",
  description: "Episapient.io",
  url: "https://episapient.appworkdemo.com/wallet/backtoapp",
  icons: ["https://avatars.mywebsite.com/"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [PolygonMainnet],
  projectId,
  enableAnalytics: true,
});

export default function BecameWarden() {
  const navigate = PagesIndex.useNavigate();
  const location = PagesIndex.useLocation();
  const themeColor = new URLSearchParams(location.search).get("mode");

  const [isLoaded, setLoaded] = useState(false);
  const [processingLog, setProcessingLog] = useState();
  const [isVisible, setIsVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const { wardenID, amount } = PagesIndex.useParams();
  const { walletProvider } = useWeb3ModalProvider();

  async function handleApprove() {
    setLoading(true);
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = provider.getSigner();
    const balance = await signer.getBalance();
    const gasPrice = await provider.getGasPrice();
    let gasCost;
    const tokenContract = new ethers.Contract(
      "0x3C1e77fb138a2448153008bBe69803285c38ce96",
      tokenAbi,
      signer
    );
    try {
      if (Number(balance.toString()) === 0) {
        setProcessingLog("You have insufficient MATIC balance in your wallet");
        setLoading(false);
        return;
      } else {
        setProcessingLog("");
      }
      setProcessingLog("Approving the transaction...");
      let fees2 = ethers.utils.parseEther(amount?.toString());
      const tokenBalance = await tokenContract.balanceOf(address);

      if (Number(tokenBalance.toString()) < Number(fees2.toString())) {
        setProcessingLog("You have insufficient token balance");
        setLoading(false);
        return;
      }

      const approveEstimatedGasLimit = await tokenContract.estimateGas.transfer(
        "0x01729d1848e02080532bed93bae7E5Dba513E557",
        fees2.toString()
      );
      gasCost = gasPrice.mul(approveEstimatedGasLimit);
      if (balance.lt(gasCost)) {
        setProcessingLog("You have insufficient gas fees for transfer token");
        setLoading(false);
        return;
      }

      const tx = await tokenContract.transfer(
        "0x01729d1848e02080532bed93bae7E5Dba513E557",
        fees2.toString()
      );
      await tx.wait();
      setProcessingLog("Executing smart contract for payment fees...");
      const receipt = await provider.getTransactionReceipt(tx.hash);
      console.log(receipt, "rererer");

      if (receipt.status === 1) {
        if (tx.hash) {
          let values = { userId: wardenID, tx_hash: tx.hash };
          setProcessingLog("Executing api call...");
          setTimeout(() => {
            becameWarden(values).then((res) => {
              if (res?.status === 201) {
                setLoading(false);
                navigate("/wallet/success");
              } else {
                setLoading(false);
                navigate("/wallet/success");
              }
              setProcessingLog("");
            });
          }, 1000);
        } else {
          setLoading(false);
          alert("Transaction is not completed. please try again");
        }
      } else {
        setLoading(false);
        alert("Transaction failed");
      }
    } catch (error) {
      setLoading(false);
      alert(error?.message);
      console.log("error?.message", error?.message);
    }
  }

  const { address, isConnected } = useWeb3ModalAccount();
  useEffect(() => {
    if (isConnected && isLoaded) {
      handleApprove();
    }
  }, [isConnected, isLoaded]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  // 10 Min timer
  const [timeLeft, setTimeLeft] = useState(600); // 600 seconds = 10 minutes
  useEffect(() => {
    if (loading) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
      return () => clearInterval(timer); // Clean up the interval on component unmount
    } else {
      setTimeLeft(600);
    }
  }, [loading]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <>
      <div
        //className="wallet-box"
        className={`${
          themeColor === "dark"
            ? "wallet-box wallet-box-content-dark"
            : "wallet-box wallet-box-content"
        }`}
      >
        <div className="wallet-box-cont">
          <div className="logo-box">
            <img src={PagesIndex.Png.logo2} alt="" />
            <div className="logo-title">EPISAP</div>
          </div>
          {/* <w3m-button /> */}
          {!isConnected && <w3m-button />}
          <div className="add-word-break">{address}</div>
          <h4 className="wallet-title">
            Warden Fees <br />
            {amount} SAP
          </h4>
          <div className="wallet-btn-approve">
            {address && (
              <button
                onClick={() => handleApprove()}
                disabled={!isConnected || loading}
                className={`loader-white ${
                  !isConnected ? "disable-color" : ""
                }`}
              >
                {loading == true ? <CircularProgress size={16} /> : "Approve"}
              </button>
            )}
          </div>
          <div className="wallet-subtitle">{processingLog}</div>
          {loading && (
            <div className="payment-timer">
              <p>
                <strong>
                  The payment is still processing. Please don't close the app or
                  turn off your screen. Wait until you get a response from the
                  wallet. If you don't, you might lose your data.
                </strong>
              </p>
              <h3>Countdown Timer</h3>
              <div style={{ fontSize: "1.5rem" }}>{formatTime(timeLeft)}</div>
            </div>
          )}
        </div>
      </div>
      {isVisible && (
        <div className="wallet-loader">
          <WalletLoader />
        </div>
      )}
    </>
  );
}
