import React, { useState } from "react";
import { ethers } from "ethers";
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";

import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import tokenAbi from "../../Connectivity/abi/tokenAbi.json";
import marketplaceAbi from "../../Connectivity/abi/marketplace.json";
import { sellIdea } from "../../../redux/admin/action";
import PagesIndex from "../../PagesIndex";

const projectId = "2fa6c12dde1b5cd1a88e4fd4cb690bca";
const PolygonMainnet = {
  chainId: 137,
  name: "Polygon Mainnet",
  currency: "MATIC",
  explorerUrl: "https://polygonscan.com/",
  rpcUrl:
    "https://polygon-mainnet.infura.io/v3/8c79ca71179c4e318999034a96a93f31",
};

const metadata = {
  name: "Episapient.io",
  description: "Episapient.io",
  url: "https://episapient.appworkdemo.com/wallet/backtoapp",
  icons: ["https://avatars.mywebsite.com/"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [PolygonMainnet],
  projectId,
  enableAnalytics: true,
});
export default function SellIdea() {
  const { walletProvider } = useWeb3ModalProvider();
  // wallet_address/:nft_id/:amount
  const { wallet_address, nft_id, amount } = PagesIndex.useParams();
  console.log(wallet_address, nft_id, amount);
  const [price, setPrice] = useState(amount);
  const [tokenId, setTokenId] = useState(nft_id);
  async function handleSell(price, tokenId) {
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = provider.getSigner();
    const tokenContract = new ethers.Contract(
      "0x3C1e77fb138a2448153008bBe69803285c38ce96",
      tokenAbi,
      signer
    );
    const contract = new ethers.Contract(
      "0xD5Dd555d1B6EB4e2C547F1ceE9007d475cB566A2",
      marketplaceAbi,
      signer
    );
    try {
      const totaltoWei = ethers.utils.parseEther(price.toString());
      console.log(totaltoWei);
      console.log(tokenId);
      let platformfee = await contract.sellNFT(
        "0x01729d1848e02080532bed93bae7E5Dba513E557",
        tokenId,
        totaltoWei
      );
      await platformfee.wait();
      console.log("sell successful!", platformfee);

      let details = await contract.marketItem(tokenId);
      console.log(details.price.toString(), "details");
      console.log("sell successful!");
      let values = {
        id: "660a8adfd8d615d67dca4c7c",

        tx_hash: platformfee.hash,
      };

      // setProcessingLog("Executing api call");
      sellIdea(values).then((res) => {
        if (res?.status === 201) {
          alert("Payment done. Go Back to your app. 201");
        } else {
          alert("Payment done. Go Back to your app.");
        }
        // setProcessingLog("");
      });
    } catch (error) {
      alert(error?.message);
      console.error("Error while approving:", error);
    }
  }
  return (
    <div>
      <w3m-button />
      <h1>SellNFT Idea</h1>
      <input
        type="number"
        value={tokenId}
        onChange={(e) => setTokenId(e.target.value)}
      />
      <input
        type="number"
        value={price}
        onChange={(e) => setPrice(parseFloat(e.target.value))}
      />
      <button onClick={() => handleSell(price, tokenId)}>Evolute Idea</button>
    </div>
  );
}
